.container {
  display: flex;
  height: 100vh;
  padding-left: 240px;

  .page-title {
    font-family: Roboto,sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 41px;
  }

  .content {
    margin-top: 40px;
    padding:0 16px 16px;
  }
}

@media screen and (max-width: 768px){
  .container {
    padding-right: 16px;
    padding-left: 60px;
  }
}