@import "../../assets/scss/variables";

.auth-page {
  background-color: $color-grey;
  height: 100vh;

  .title {
    width: 100%;
    text-align: center;
    color: $color-main;
    font-weight: 600;
    font-size: 24px;
  }

}