@import "../../assets/scss/variables";

.profile-page {
  width: 100%;

  .avatar-container {
      width: 100%;
      display: flex;
      justify-content: center;

    .avatar {
      height: 100px;
      width:  100px;
      line-height: 100px;
      font-size: 20px;
    }

    .change-profile {
      cursor: pointer;
      position: relative;

      .change-icon {
        background-color: $color-main;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        position: absolute;
        top: 68px;
        left: 68px;

        i {
          color: #fff;
        }
      }

      #profile-input {
        display: none;
      }
    }
  }
}