@import "./assets/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: 'Roboto',sans-serif !important;
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.p-inputtext {
  &:enabled:hover {
    border-color: $color-main;
  }

  &:enabled:focus {
    border-color: $color-main;
    box-shadow: none;
  }
}

.pi {
  font-family: 'primeicons' !important;
}

.p-dropdown {
    &.p-focus {
      border-color: $color-main !important;
      box-shadow: none !important;
    }

  &:hover {
    border-color: $color-main !important;
    box-shadow: none !important;
  }
}

.p-dropdown-item {
  color: #000 !important;
}

.p-button-outlined {
  border-color: $color-border !important;
  color: #000 !important;
}

.form-element-is-invalid {
  color: $color-main;
}

.p-dialog {
  z-index: 99999999;
}

.grid {
  margin: 0 !important;
}

.p-badge {
  min-width: 102px;
}

.p-card-body {
  width: 100% !important;
}

.childrenCard {
  .p-card-content {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

body:has(.p-dialog-mask) {
  overflow: hidden !important;
}
